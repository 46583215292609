import { Link } from "react-router-dom";

function Header() {
  function handleLogout() {
    localStorage.removeItem("token");
    window.location.href = "/login";
  }
  return (
    <>
      <div className="header">
        <div className="menu-toggle-btn">
          <Link to="#">
            <i className="bi bi-list"></i>
          </Link>
        </div>

        <img
          width="100"
          className="logo"
          src="https://www.mclbaramulla.com/assets/imgs/theme/logo.png"
          alt="logo"
        />

        <div className="header-bar ms-auto">
          <ul className="navbar-nav justify-content-end">
            <li className="nav-item ms-3">
              <button
                onClick={handleLogout}
                className="btn btn-primary btn-icon"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
