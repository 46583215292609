import { SchoolFormValues } from "./../types/school";
import axios, { AxiosResponse } from "axios";
import { School, SchoolsResponse } from "../types/school";

const API_URL = process.env.REACT_APP_API_URL;

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

export default async function getListOfSchools(): Promise<School[]> {
  const response: AxiosResponse<SchoolsResponse> = await axios.get(
    `${API_URL}/schools/`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Add bearer token to Authorization header
      },
    }
  );
  return response.data.output;
}

export async function addSchool(school: SchoolFormValues) {
  try {
    const response = await axios.post(`${API_URL}/schools`, school, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Add bearer token to Authorization header
      },
    });

    if (response.status === 201) {
      return response.data.message;
    }
  } catch (error) {
    return undefined;
  }
}

export async function getCountOfSchools() {
  return await axios
    .get(`${API_URL}/schools/count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.output;
      }
    })
    .catch((err) => console.log(err));
}
export function getSingleSchool(id: number) {
  return axios
    .get(`${API_URL}/schools/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.output;
      }
    })
    .catch((err) => console.log(err));
}

export async function updateSchool(id: number, student: SchoolFormValues) {
  try {
    const response = await axios.put(
      `${API_URL}/schools/${id}`,
      student,
      config
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return undefined;
  }
}

export async function deleteSchool(id: number) {
  try {
    const response = await axios.delete(`${API_URL}/schools/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    return undefined;
  }
}
